import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Strona główna | Lival Resort
			</title>
			<meta name={"description"} content={"Odkryj spokój nad morzem"} />
			<meta property={"og:title"} content={"Strona główna | Lival Resort"} />
			<meta property={"og:description"} content={"Odkryj spokój nad morzem"} />
			<meta property={"og:image"} content={"https://voplaygo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://voplaygo.com/img/sm_5afec7edb42de.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://voplaygo.com/img/1.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Lival Resort
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Zanurz się w kojącym uścisku oceanu w Lival Resort. Nasz zaciszny ośrodek przy plaży oferuje spokojne sanktuarium, w którym delikatne dźwięki fal stanowią idealne tło dla relaksu i odmłodzenia.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
							padding="0 0 0 0"
							background="0"
							color="--primary"
						>
							Skontaktuj się z nami
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
						Nadmorska przystań
					</Text>
					<Text margin="0px 0px 70px 0px" font="--lead" lg-margin="0px 0px 40px 0px" color="#626970">
						Położony na dziewiczym odcinku piaszczystej plaży, nasz ośrodek jest harmonijnym połączeniem luksusu i naturalnego piękna. Doświadcz najwyższego komfortu w naszych elegancko urządzonych pokojach, oddaj się światowej klasy usługom spa lub delektuj się najświeższymi lokalnymi smakami w naszej restauracji przy plaży. W Lival każdy szczegół jest dopracowany, aby stworzyć niezapomniany pobyt.
					</Text>
					<Image
						src="https://voplaygo.com/img/2.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Dlaczego warto wybrać Lival?
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Nadmorski spokój: Obudź się z zapierającym dech w piersiach widokiem na ocean i spokojnym szumem fal.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Gourmet Getaway: Nasza restauracja oferuje kulinarną podróż z daniami przygotowanymi z lokalnych produktów.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Spa i wellness: Rozpieszczaj się odmładzającymi zabiegami w naszym spa z pełnym zakresem usług.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 70px 0px" lg-margin="0px 0px 40px 0px">
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Ekskluzywne doświadczenia: Od prywatnych kolacji na plaży po jogę o wschodzie słońca, tworzymy chwile, które inspirują.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});